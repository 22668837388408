<template>
  <my-list-card
      :headers="headers"
      :loading="loading"
      show-add-action
      show-detail
      show-edit-action
      show-delete-action
      resource="projeto-pesquisa/solicitacao"
      title="Solicitações de Projeto"
  >
    <template v-slot:item.show="{ item }">
      <pesquisa-solicitacao-projeto-detail-dialog
        :itemId="item.id"
      />
    </template>
    <template #item.situation.description="{ item }">
      <v-chip
          class="font-weight-medium"
          :color="item.situation.color"
          dark
          label
          small
      >
        {{ item.situation.id !== 1 ? item.situation.description : 'Pendente' }}
      </v-chip>
    </template>
    <template #item.tsp.situation.description="{ item }">
      <v-chip
          class="font-weight-medium"
          :color="item.tsp? item.tsp.situation.color : 'grey'"
          dark
          label
          small
      >
        {{ item.tsp? item.tsp.situation.description: 'Pendente' }}
      </v-chip>
    </template>
    <template v-slot:item.issued_at="{ item }">
      {{ item.issued_at | date }}
    </template>

    <template v-slot:item.actions="{ item, resource }">
      <my-action-confirmation
          action="update"
          :disabled="item.situation.id > 1"
          icon="mdi-file-send-outline"
          :resource="`projeto-pesquisa/solicitacao/${item.id}/solicitar-analise`"
          tooltip="Solicitar análise"
          @success="updateItemSituation(item)"
      />
    </template>
  </my-list-card>
</template>

<script>
import MyListCard from "@/view/components/MyListCard";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import PesquisaSolicitacaoProjetoDetailDialog
  from "@/view/pages/projeto-pesquisa/solicitacao/Components/DetailDialog.vue";
import MyActionConfirmation from "@/view/components/MyActionConfirmation.vue";
import ApiService from "@/core/services/api.service";
export default {
  components: {MyActionConfirmation, PesquisaSolicitacaoProjetoDetailDialog, MyListCard },

  data: () => ({
    loading: false,
    headers: [{
      align: 'center',
      text: 'Status Projeto',
      value: 'situation.description',
      width: 0,
    }, {
      align: 'center',
      text: 'Status TSP',
      value: 'tsp.situation.description',
      width: 0,
    }, {
      text: 'Nome do Projeto',
      value: 'project_name',
    }, {
      text: 'Solicitante',
      value: 'owner_name',
    }, {
      text: 'Parceiro do Projeto',
      value: 'partner_name',
    }, {
      align: 'center',
      text: 'Data Solicitação',
      value: 'issued_at',
      width: 0,
    }, {
      value: 'actions',
      width: 116,
    }]
  }),

  methods: {
    async updateItemSituation(item) {
      try {
        this.loading = true
        const [projectSituation, tspSituation] = await Promise.all([
          ApiService.get(`projeto-pesquisa/solicitacao/${item.id}/situacao-projeto`),
          ApiService.get(`projeto-pesquisa/solicitacao/${item.id}/situacao-tsp`)
        ]);
        item.situation = projectSituation.data
        item.tsp.situation = tspSituation.data
      } catch (e) {
        this.$refs.snackbar.show(
            'Desculpe, algo deu errado!',
            e,
            'danger',
        )
      }

      this.loading = false
    },

    disableEditAction(item) {
      return item.tsp.situation.id > 1
    },

    disableDeleteAction(item) {
      return item.tsp.situation.id > 1
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Pesquisa' },
      { title: 'Solicitações de Projeto' },
    ])
  },
}
</script>