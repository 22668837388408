import { VChip } from 'vuetify/lib/components/VChip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('my-list-card',{attrs:{"headers":_vm.headers,"loading":_vm.loading,"show-add-action":"","show-detail":"","show-edit-action":"","show-delete-action":"","resource":"projeto-pesquisa/solicitacao","title":"Solicitações de Projeto"},scopedSlots:_vm._u([{key:"item.show",fn:function(ref){
var item = ref.item;
return [_c('pesquisa-solicitacao-projeto-detail-dialog',{attrs:{"itemId":item.id}})]}},{key:"item.situation.description",fn:function(ref){
var item = ref.item;
return [_c(VChip,{staticClass:"font-weight-medium",attrs:{"color":item.situation.color,"dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(item.situation.id !== 1 ? item.situation.description : 'Pendente')+" ")])]}},{key:"item.tsp.situation.description",fn:function(ref){
var item = ref.item;
return [_c(VChip,{staticClass:"font-weight-medium",attrs:{"color":item.tsp? item.tsp.situation.color : 'grey',"dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(item.tsp? item.tsp.situation.description: 'Pendente')+" ")])]}},{key:"item.issued_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.issued_at))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
var resource = ref.resource;
return [_c('my-action-confirmation',{attrs:{"action":"update","disabled":item.situation.id > 1,"icon":"mdi-file-send-outline","resource":("projeto-pesquisa/solicitacao/" + (item.id) + "/solicitar-analise"),"tooltip":"Solicitar análise"},on:{"success":function($event){return _vm.updateItemSituation(item)}}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }